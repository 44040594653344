@import url("https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap");

/* #39FF14; */
:root {
  /* Set neon color */
  --normalPadding: 3%;
  /* --normalColor: rgb(21, 255, 0);
  --detailColor: rgb(17, 0, 255); */
  --normalColor: lightgrey;
  /* --detailColor: rgb(246, 124, 255); */
  --detailColor: blue;
  --textColor: blue;
  --linkColor: rgb(255, 0, 217);
}

* {
  box-sizing: border-box;
  font-family: "Pathway Gothic One", sans-serif;
  cursor: none;
}
button, a, .standard-button{
  cursor: pointer;
}


.main-cursor{
  pointer-events: none;
  width: 50px;
  height: 50px;

  background-color: var(--normalColor);
  mix-blend-mode: multiply !important;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.main-cursor-ring{
  pointer-events: none;
  width: 100px;
  height: 100px;
  border: 2px solid var(--detailColor);
  border-radius: 50%;

}

.hor{
  height: 2px;
  right: 0;
  left: 0;
  top: 30px;
  position: fixed;
  background-color: var(--detailColor);
  pointer-events: none;

}
.ver{
  width: 2px;
  left: 30px;
  top: 0;
  bottom: 0;
  position: fixed;
  background-color: var(--detailColor);
  pointer-events: none;

}
.projectTitle{
  color: var(--detailColor);
  font-weight: 200;
  /* font-size: 23px; */
}
.projectYear{
  margin: 10px;
  position: absolute;
  top:0;
  right:0;
}
.projectRecap{
  margin-top: 10px;
  margin-bottom: 10px;
}
.projectRecap p{
  color: var(--detailColor);
  font-weight: 200;
  font-size: 16px;
}




*,
html,
body,
#root {
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior: none;
  font-family: 'Inter var', sans-serif;
  cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxMCIgZmlsbD0iYmxhY2siLz48L3N2Zz4='),
    auto;
}
.mainbackground{
  position: fixed;
  top: 0;
  left: 0;

  background-color: #fff;
  background-image: linear-gradient(
      45deg,
      var(--normalColor) 25%,
      transparent 25%,
      transparent 75%,
      var(--normalColor) 75%,
      var(--normalColor)
    ),
    linear-gradient(
      -45deg,
      var(--normalColor) 25%,
      transparent 25%,
      transparent 75%,
      var(--normalColor) 75%,
      var(--normalColor)
    );
  background-size: 10px 10px;
  background-repeat: repeat;
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: 50% 50%;
  height: 1500%;
  width: 1500%;



}



body {
  /* background-color: var(--normalColor); */

	/* animation: gradient 1s ease infinite; */
	/* height: 100vh; */

}


@keyframes gradient {
	0% {
		background-size: 100% 100%;
	}
	50% {
		background-size: 500% 500%;
	}
	100% {
		background-size: 100% 100%;
	}
}



body, #root, .app {
  width: 100%;

}

.scrollcontent {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  animation: animateBg 14s linear infinite;
  /* background-image: linear-gradient(90deg,#ffff00,#00ff00,#00ffff,#ffb8fd,#a6f680,#ffff00,#00ff00); */
  /* background-image: url("https://cdn.sanity.io/images/swdt1dj3/production/f4e61303a8644e4a3c21feb505bd19268f2d739a-2362x2362.jpg?w=1000&h=1000&fit=max");
  background-size: 600% 600%; */
}
@keyframes animateBg {
  0% { background-position: 100% 0%; }
  100% { background-position: 0% 0%; }
}


.card {
  position: relative;
  display: inline-block;
  max-height: 100%;
  height: 100%;
  width: 100%;

}
.card .link{
  position: absolute;
  top:50%;
  transform: translate(0, -50%);

}

.surroundingImg{
  position: relative;
  max-width:500px;

  height: auto;
  max-height: 100%;
  object-fit: contain;
  margin: -30px;
  mix-blend-mode: difference;


}
.surroundingImg:hover{

  mix-blend-mode: normal;


}

/*
.card-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
} */




.scroll {

  height: auto;
  position: absolute;
  flex-basis: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.8);
  color: darkgrey;
  display: none;
  transition: 2s;
}

.card-container:hover .scroll {
  display: block;
  transition: 2s;
}



.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  height: 100% !important;

}















/* .main {
  width: 100%;
  min-height: calc(100vh - 50px);
  position: relative;
  top: 29px;

  padding: 0;
  background-color: darkred;
} */

.content {
  max-width: 1200px;
  width: 90%;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  top: 0;
  padding: 0 10px;
  padding-top: 20px;
}

.card {
  padding: 10px;
  padding-left: 0px;
  position: relative;
  display: inline-block;
  width: fit-content;
  max-height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}



.card-img-container {
  height: fit-content;
  width: fit-content;


}




.card-container {
  position: fixed;
  top: 0;
  left:0;
  right:0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  overflow-y: hidden;
}

.scroll {
  padding: 5px;
  /* height: 235px; */
  line-height: 235px;
  position: absolute;
  flex-basis: 0;
  z-index: 5;

  display: none;
  transition: 2s;
}

.card-container:hover .scroll {
  display: block;
  transition: 2s;
}

.scroll-prev {
  left: 10px;
}

.scroll-next {
  right: 10px;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;


  align-items: center;

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}





















.mainCanvas{
  position: fixed !important;
  top: 0;
 bottom: 0;
  right: 0;
  left: 0;
  cursor: none;
}

h1 {
  font-size: 30vw;
  font-weight: 400;
  letter-spacing: -0.05em;
  margin: 0;
  padding: 0;
  color: var(--detailColor);
  font-weight: 200;

  height: auto;
  color: var(--detailColor);
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff,
  0 0 10px white, 0 0 20px white,
  0 0 22px var(--detailColor), 0 0 25px var(--detailColor),
  0 0 33px var(--detailColor);
  padding: 5px 20px 5px 20px;

  font-family: "Pathway Gothic One", sans-serif;

  mix-blend-mode:difference;
}
.noshade div,
.noshade {
  /* border-radius: 300%; */
  border: 0 solid white;
  /* box-shadow: 0 0 0px #fff, 0 0 0px #fff, 0 0 0px #fff,
    0 0 0px var(--detailColor), 0 0 0px var(--detailColor),
    0 0 0px var(--detailColor), 0 0 0px var(--detailColor),
    0 0 0px var(--detailColor); */
}
.noshade p,
.noshade a {

    color: var(--detailColor);
    font-weight: 200;
    font-size: 23px;
}


.background{
  background-color: var(--normalColor);
}

.fixedMiddle{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 80em;
  height: 80em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid var(--detailColor);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



body {
  /* background: var(--normalColor); */

  min-height: 100vh;
  z-index: -2;
  overflow-x: hidden;
}

*::-webkit-scrollbar {
  display: none;
}
a,
p {
  mix-blend-mode: unset;
}

.cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 500px;
  height: 500px;
  border: 50px solid var(--detailColor);
  background-color: var(--detailColor);
  transition: 80ms ease-out;
  border-radius: 50%;
  mix-blend-mode: screen;
  pointer-events: none;
}

img {
  max-width: 100%;
  transform: translateZ(0);
  max-height: 100%;
  object-fit: contain;
}
.background{
  background-color: var(--normalColor);
}

.fullwidth {
  width: 100%;
}

p {
  font-size: 20px;
  text-align: left;
  color: var(--detailColor);
}
h3 {
  font-size: 25px;
  color: var(--textColor);
  text-decoration: underline;
  text-align: left;
}
h2 {
  color: var(--textColor);
}
h1 {
  margin-bottom: 50px;
}

strong {
  color: var(--textColor);
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

a {
  text-decoration: none;
  font-size: 20px;
  color: var(--textColor);
  z-index: 9999999999999;
}
a:hover {
  color: var(--linkColor);
}
a.visited {
  color: inherit;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.modeNav{
  margin: 10px;
  position: fixed;
  bottom: 0;
}
.modeNav button{
  width: fit-content;
}
.modeNav button:hover{

  color: var(--linkColor);
  z-index: 9999999999999;
}
.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.align-right {
  align-items: right;
  justify-content: flex-end !important;
}
.align-center {
  align-items: center;
  justify-content: space-around;
}

.justifyBetween {
  justify-content: space-between;
}

.standard-container{
  color: var(--detailColor);
  overflow: hidden;
  padding: 5px 20px 5px 20px;
  border: 2px solid var(--detailColor) !important;
}
.standard-button {
  display: inline-block;
  font-weight: 200;
  font-size: 15px;
  height: auto;
  color: var(--detailColor);
  background-color: var(--normalColor);
  /* text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff,
  0 0 10px black, 0 0 20px black,
  0 0 22px var(--detailColor), 0 0 25px var(--detailColor),
  0 0 33px var(--detailColor); */
  padding: 5px 20px 5px 20px;
  border: 2px solid var(--detailColor) !important;
  /* box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff,
    0 0 10px var(--detailColor), 0 0 20px var(--detailColor),
    0 0 22px var(--detailColor), 0 0 25px var(--detailColor),
    0 0 33px var(--detailColor);
    border-radius: 25px; */
  white-space: nowrap;
  border-radius: 30px;
  font-family: "Pathway Gothic One", sans-serif;


  /* mix-blend-mode: exclusion; */
}
.mode{
  font-style: italic;
  text-decoration: line-through;
}
.mode.active{
  font-style:normal;
  text-decoration: none;
}

.customfixed{
  position: fixed !important;
  top: 50%;
  transform: translate(0,-50%);
  z-index: -10;
}
.customFixedSpace{
  top: 170px !important;
}

.projectnamecontainer{
  margin: 10px;
  z-index: 20;


}
.projectnamecontainer  p{
  text-align: right;
}

.projectdesc{
  mix-blend-mode: difference !important;
}

.inverse {
  border: 2px solid var(--normalColor) !important;
  color: var(--normalColor) !important;
}


.borderTop {
  border-top: 2px solid var(--detailColor);
  margin-top: 10px;
  margin-bottom: 10px;
}


.tag,
.tag_button {
  margin-right: 5px;
  margin-top: 5px;
  background-color: rgba(0, 0, 0, 0);
}
.tag.active,
.tag_button.active {
  /* box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff,
    0 0 10px var(--detailColor), 0 0 20px var(--detailColor),
    0 0 22px var(--detailColor), 0 0 25px var(--detailColor),
    0 0 33px var(--detailColor); */
  color: var(--normalColor);
  background-color: var(--detailColor);
}
.project_tag,
.project_tag p{
  font-size: 13px;
  margin-top:0px;
  color: var(--detailColor);
}
.mainImage{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainImage span{
  max-width: 50%;
  max-height: 50%;
  min-height: 50%;
  min-width: 50%;
  display: flex !important;
  justify-content: center;
  align-items: center;


}
.projectDetails{
  max-width: 500px;

  padding-bottom: 20px;
}
@media only screen and (min-width: 600px){
  .projectDetails{
    max-height:500px;
    overflow-y: scroll;
  }
}


.mainImage {
  margin: 0;
}
.slide div,
.slide div .mainImage {
  height: 100%;
  object-fit: cover;
  width: auto !important;
}

.mainContainer {

}

.regContainer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.singlePost {
  position: relative;
}

/* frontpage grids */

/* tags */

.project_main_image {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

}
.project_main_image img{
  width: 100%;

}



/* posts */

.post_grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
.post_card {
  position: relative;
  height: auto;
}
.post_card_image {
  object-fit: cover;
  width: 120%;
  max-width: 120% !important;
  margin-bottom: -20%;
}

.post_card_overlay {
  position: absolute;
  z-index: 99;
  padding: 10%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  justify-items: space-between;
  background-color: var(--detailColor);
  mix-blend-mode: exclusion;
}
.post_card_overlay h1,
.post_card_overlay h2,
.post_card_overlay h3,
.post_card_overlay p {
  color: var(--normalColor);
}

.tag {
  margin-right: 5px;
}
.recap {
  padding-top: 20px;
}

.headerNav {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999999999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-bottom: 0;
}

.footer-nav{
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
  /* padding: 0 var(--normalPadding) var(--normalPadding) var(--normalPadding); */
}


.loading_img{
  width: 600px;
  height: 600px;
  max-width: 100%;
  background-color:var(--detailColor);
  box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff,
  0 0 10px var(--detailColor), 0 0 20px var(--detailColor),
  0 0 22px var(--detailColor), 0 0 25px var(--detailColor),
  0 0 33px var(--detailColor);
}
.loading_img img{
  width: 100%;
  object-fit: contain;

}
.fixedUnderHeader {
  position: fixed;
  top: 42px;
  left: 0;
  right: 0;
  z-index: 9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--normalPadding);
}

.logo_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  max-height: 100px;
}
.logo_container img {
  position: absolute;
  z-index: -1;
}
.logo_container p {
  white-space: nowrap;
}
.overlayComp{
  position: absolute;
  top:0px;

}
.padding-top{
  padding-top: 100px;

}
.projectList2022 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: var(--normalPadding);
  width: 100%;

}
/* .projectList-item:first-of-type {
  margin-top: 40px;
  margin-bottom: 20px;
} */
.projectList-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid var(--detailColor);
  padding-right: 10px;
  padding-left: 10px;
}

.projectList-item div {
  flex-grow: 1;
  text-align: left;
}
.projectList-item .categories {
  margin-right: 20%;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 200px;
}
.projectList-item .categories a {
  width: auto;
  position: relative;
}

.thumbnail {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 80%;
  z-index: 999999999;
}

/* featured posts */
.carousel {
  overflow: visible !important;
  max-width: 800px;
  padding-bottom: 30px;
}

.carousel.slide img {
  width: 300px !important;
}

.nextArrow {
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translate(50%, -50%);
}

.arrow {
  width: 40px;
  height: 40px;
  border: 2px var(--detailColor) solid;
  background-color: rgba(0, 0, 0, 0);
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--textColor);
}

.prevArrow {
  position: absolute;

  left: -40px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dot {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
  background-color: var(--detailColor) !important;
  opacity: 1 !important;
}
.dot.selected {
  opacity: 0.3 !important;
}

/* masonry */

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;

}
.my-masonry-grid_column {
  padding-right: 2px;
  background-clip: padding-box;
}
.my-masonry-grid_column:last-of-type {
  padding-right: 0px;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* margin-bottom: 15px; */
}

.singleProjectMasonry img {
  width: 100%;
}
/* footer */

footer {
  min-height: 50vh;
  background-color: var(--detailColor);
  padding: var(--normalPadding);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 999999999999999;
  position: absolute;
  top: 99%;
  width: 100%;
    box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff,
    0 0 10px var(--detailColor), 0 0 20px var(--detailColor),
    0 0 22px var(--detailColor), 0 0 25px var(--detailColor),
    0 0 33px var(--detailColor);
}

.absoluteplacement{

}
footer p {
  color: var(--normalColor);
}

.social_media_icon {
  height: 35px;
  object-fit: contain;
}

.blockContent figure {
  margin-top: var(--normalPadding);
  margin-bottom: var(--normalPadding);
}

.content {

  padding-top: 15px;
  border-top: 2px solid var(--detailColor);
  margin-bottom: 500px;
}
.contentBlock {
  max-width: 90%;
}
.project_directory_line{
  margin-left: 10px;

}
.project_directory_line a,
.project_directory_line p {
  font-size: 13px;
}
.projects{
  margin:10px;

}
.gallery{
  max-width: 1000px;

}
.projectList{
  width: 100%;
}
.projectHeader{
  height: 50px;
}

@media only screen and (max-width: 700px) {
  .to-column {
    flex-direction: column;
    margin-bottom: 50px;
  }

  .gallery{
    padding-top:100px;

  }
  .projectHeader{
    height: 95px;
  }

  .projectMedia{
    padding-top: 100px;

    padding-bottom: 100px;

  }
  .projectList{
    width: 100%;
    padding-bottom: 300px;
  }

  .featured_post_card_image {
    flex-grow: 2;
    max-width: 100%;
    height: 50%;
  }
  .post_card_image {
    object-fit: cover;
    width: 120%;
    max-width: 100% !important;
    margin-bottom: 0;
  }

  .featured_post_card_overlay {
    max-width: 100%;
    height: 50%;
  }

  .authorImage {
    max-width: 100%;
    margin-bottom: var(--normalPadding);
  }
  .authorInfo {
    max-width: 100%;

  }
  .nextArrow {
    position: absolute;
    right: 0px;
    top: 100%;
    transform: translate(0%, -0%);
  }

  .prevArrow {
    position: absolute;
    left: 0px;
    top: 100%;
    transform: translate(-0%, -0%);
  }
  .carousel {
    margin-bottom: 50px !important;
  }
}

@media only screen and (max-width: 500px) {


  .logo_container,
  .logo_container p {
    width: 100%;
    text-align: center;
  }
  .logo_container{
    margin-bottom: 2px;
  }

  nav {
    /* position: relative; */

    right: 0;
    max-width: 100%;
    z-index: 9999999999;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    align-items: center;
  }


  .mainContainer {
    /* padding-top: 130px; */
  }

  .singlePost {
    /* margin-top: 75px; */
    position: relative;

  }
  .contentColumn{
    /* padding-bottom: 75px;
    margin-bottom: 75px; */
  }



  .fixedUnderHeader {
    position: fixed;
    top: 82px;
    left: 0;
    right: 0;
    z-index: 9999999999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--normalPadding);
  }
}

@media only screen and (min-width: 1000px) {
  .mainContainer {
    /* padding-left: 10%;
    padding-right: 10%; */
  }
}


.project-plane-container{
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 10px;
}
.project-plane-container a, 
.project-plane-container button{

 margin-right: 10px;
}
.leftcorner{
  position: fixed;
  bottom: 0;
  left : 0;

  margin: 10px;
}